<template>
  <div class="index">
    <div class="cw_title">{{$t('main.foaocgwc')}}</div>
    <div class="more_title">{{$t('main.appremoretr')}}</div>
    <img src="../assets/img/gzhcode.png" class="code" alt="">
    <div class="content">{{$t('main.vtowocgwc')}}<br>{{$t('main.konwMore')}}</div>
    <div class="link_button" @click="goGW">
        <img src="../assets/img/link.png" alt="">
        {{$t('main.titleWeb')}}</div>
  </div>
</template>

<script>
export default {
    methods:{
        goGW(){
            window.location.href = 'http://ccwcds.com'
        }
    }
}
</script>

<style scoped>
.index{
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    font-size: 0.18rem;
    color: #000;
    box-sizing: border-box;
	background: url(../assets/img/index_bg.png) no-repeat center;
	background-size: cover;
    font-weight: 600;
    padding-top: 0.39rem;
}
.cw_title{
    position: relative;
    
}
.cw_title::before{
    content: '';
    display: block; position: absolute;
    left: -0.3rem;
    top: 0.08rem;
    background: url(../assets/img/bjgw.png) no-repeat;
    background-size: cover;
    width: 0.17rem;
    height: 0.13rem;
}
.cw_title::after{
    content: '';
    display: block; position: absolute;
    right: -0.3rem;
    top: 0.08rem;
    background: url(../assets/img/bjgw.png) no-repeat;
    background-size: cover;
    width: 0.17rem;
    height: 0.13rem;
    transform: rotateY(180deg);
}
.code{
    width: 2.4rem;
    height: 2.5rem;
    display: block;
    margin-bottom: 0.27rem;
}
.more_title{
    margin-top: 0.17rem;
    margin-bottom: 0.32rem;
}
.content{
    font-size: 0.14rem;
    line-height: 0.28rem;
}
.link_button{
    margin-top: 0.3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #9a2a25;
    height: 0.485rem;
    font-size: 0.18rem;
    color: #fff;
    width: calc(100% - 0.3rem);
    border-radius: 0.05rem;
    cursor: pointer;
}
.link_button img{
    width: 0.165rem;
    height: 0.17rem;
    margin-right: 0.125rem;
}
</style>